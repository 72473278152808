<template>
  <div class="page1">
    <!--面包屑-->
    <div class="breadcrumb">
      <div style="text-align: right">
        <el-button @click="$router.go(-1)" type="primary">返回</el-button>
      </div>
    </div>
    <!--主体内容-->
    <div class="content">
      <el-tabs v-model="activeName2" @tab-click="handleClick" class="tab-wrap">
        <el-tab-pane v-if="!authority.tabs['lbBerth']" label="泊位管理" name="thirteen">
          <berth-manage :authority="authority.tabs['lbBerth']" ref="berth"></berth-manage>
        </el-tab-pane>
        <el-tab-pane v-if="!authority.tabs['video']" label="数据采集设备管理" name="fourteen">
          <video-equipment
            :authority="authority.tabs['video']"
            ref="videoEquipment"
          ></video-equipment>
        </el-tab-pane>
        <el-tab-pane v-if="authority.tabs['lbCount']" label="通信配置" name="first">
          <ms-control :authority="authority.tabs['lbCount']" ref="msn"></ms-control>
        </el-tab-pane>
        <el-tab-pane v-if="authority.tabs['lbParameters']" label="业务参数配置" name="third">
          <business-manage
            :authority="authority.tabs['lbParameters']"
            :details="details"
            ref="business"
          ></business-manage>
        </el-tab-pane>
        <el-tab-pane v-if="authority.tabs['lbParkCarMange']" label="车辆管理" name="fourth">
          <cars-manage
            :authority="authority.tabs['lbParkCarMange']"
            :details="details"
            ref="cars"
          ></cars-manage>
        </el-tab-pane>
        <el-tab-pane v-if="authority.tabs['lbChannel']" label="设备管理" name="twelve">
          <equipment-manage
            :authority="authority.tabs['lbChannel']"
            :details="details"
            ref="equipment"
          ></equipment-manage>
        </el-tab-pane>
        <el-tab-pane v-if="authority.tabs['lbGate']" label="开闸方式管理" name="six">
          <gate-manage
            :authority="authority.tabs['lbGate']"
            :details="details"
            ref="gate"
          ></gate-manage>
        </el-tab-pane>
        <el-tab-pane v-if="authority.tabs['lbLed']" label="LED配置" name="seven">
          <led-manage
            :authority="authority.tabs['lbLed']"
            :details="details"
            ref="led"
          ></led-manage>
        </el-tab-pane>
        <el-tab-pane v-if="authority.tabs['lbCalculate']" label="计费规则" name="nine">
          <charge-rule
            :authority="authority.tabs['lbCalculate']"
            :details="details"
            ref="charge"
          ></charge-rule>
        </el-tab-pane>
        <el-tab-pane v-if="authority.tabs['debtCalculate']" label="欠费离场" name="arrears">
          <arrears-out
            :authority="authority.tabs['debtCalculate']"
            :details="details"
            ref="Aarrears"
          ></arrears-out>
        </el-tab-pane>
        <el-tab-pane v-if="authority.tabs['lbCalculate']" label="收费员管理" name="fifteen">
          <tollgatherer-manage
            :authority="authority.tabs['lbCalculate']"
            :details="details"
            ref="tollgatherer"
          ></tollgatherer-manage>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import msControl from "./parkSetChilds/MS/light";
// import monitorEquip from "./parkSetChilds/monitorEquip/index"
import businessManage from "./parkSetChilds/BusinessManage/index";
import berthManage from "./parkSetChilds/berthManage";
import carsManage from "./parkSetChilds/CarsManage/index";
import videoEquipment from "./parkSetChilds/videoEquipmentManage";
import equipmentManage from "./parkSetChilds/EquipmentManage";
import gateManage from "./parkSetChilds/GateManage";
import ledManage from "./parkSetChilds/LedManage";
import chargeRule from "./parkSetChilds/chargeRule";
import arrearsOut from "./parkSetChilds/arrearsOut";
import tollgathererManage from "./parkSetChilds/tollgathererManage";
export default {
  name: "parkConfig",
  data() {
    return {
      authority: this.$setAuthority("parkConfig") || this.$setAuthority("secondParkConfig"),
      activeName2: "thirteen",
      details: this.$route.query,
      parkAccessType: this.$route.query.parkAccessType,
    };
  },
  watch: {
    authority: {
      handler(ne, ol) {
        let key = Object.keys(ne.tabs)[0];
        console.log(113, Object.keys(ne.tabs));
        this.$nextTick(() => {
          switch (key) {
            case "msCount":
              this.activeName2 = "thirteen";
              this.$refs.berth.init();
              break;
            case "videMonitor":
              this.activeName2 = "second";
              this.$refs.monitor.init();
              break;
            case "parameters":
              this.activeName2 = "third";
              this.$refs.business.init();
              break;
            case "parkCarMange":
              this.activeName2 = "fourth";
              this.$refs.cars.init();
              break;
            case "channel":
              this.activeName2 = "five";
              this.$refs.channe.init();
              break;
            case "gate":
              this.activeName2 = "six";
              this.$refs.gate.init();
              break;
            case "led":
              this.activeName2 = "seven";
              this.$refs.led.init();
              break;
            case "box":
              this.activeName2 = "eight";
              this.$refs.police.init();
              break;
            case "calculate":
              this.activeName2 = "nine";
              this.$refs.charge.init();
              break;
            case "calculate2": // 修改tab名字
              this.activeName2 = "arrears";
              this.$refs.Aarrears.init();
              break;
            case "collector":
              this.activeName2 = "ten";
              this.$refs.collector.init();
              break;
            case "AI":
              this.activeName2 = "eleven";
              this.$refs.AI.init();
              break;
            case "equipment":
              this.activeName2 = "twelve";
              this.$refs.equipment.init();
              break;
            default:
          }
        });
      },
      immediate: true,
    },
  },
  methods: {
    handleClick(tab, event) {
      switch (tab.name) {
        case "first":
          this.$refs.msn.init();
          break;
        case "second":
          this.$refs.monitor.init();
          break;
        case "third":
          this.$refs.business.init();
          break;
        case "fourth":
          this.$refs.cars.init();
          break;
        case "five":
          this.$refs.channe.init();
          break;
        case "six":
          this.$refs.gate.init();
          break;
        case "seven":
          this.$refs.led.init();
          break;
        case "eight":
          this.$refs.police.init();
          break;
        case "nine":
          this.$refs.charge.init();
          break;
        case "arrears":
          this.$refs.Aarrears.init();
          break;
        case "ten":
          this.$refs.collector.init();
          break;
        case "eleven":
          this.$refs.AI.init();
          break;
        case "twelve":
          this.$refs.equipment.init();
          break;
        case "thirteen":
          this.$refs.berth.init();
          break;
        case "fourteen":
          this.$refs.videoEquipment.init();
          break;
        default:
      }
    },
  },
  components: {
    // monitorEquip,
    msControl,
    businessManage,
    carsManage,
    gateManage,
    ledManage,
    chargeRule,
    arrearsOut,
    equipmentManage,
    berthManage,
    videoEquipment,
    tollgathererManage,
  },
  created() {},
  mounted() {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
  margin: -12px 0 12px 0;
}

.content {
  overflow: hidden;

  .searchWrapper {
    overflow: hidden;
    background: #EFF2F7;
    padding: 22px 22px 0;
    margin-bottom: 20px;
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;
    float: right;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }

  .btnWrapper {
    text-align: left;
    margin-top: 28px;
    float: left;
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.transfer {
  margin: 0 auto;
}

.button {
  margin-bottom: 15px;
  text-align: right;
}

.ruleDetail {
  width: 500px;
  height: 150px;
  overflow: auto;
  border: 1px solid #bfcbd9;
  border-radius: 5px;
  color: #1f2d3d;
}

.feeDis {
  margin-left: 20px;
}

.berth-sort {
  margin-top: 10px;
}
</style>
<style scoped lang="stylus" rel="stylesheet/stylus">
.el-table .success-row {
  background: #f0f9eb !important;
}
.tab-wrap >>> .el-tabs__header {
  background: #FFFFFF;
  margin-bottom: -20px;
  z-index: 1;
  .el-tabs__nav-next, .el-tabs__nav-prev {
    line-height: 45px;
  }
  .el-tabs__item.is-top:nth-child(2) {
    padding-left: 20px;
  }
  .el-tabs__item {
    height: 45px;
    line-height: 45px;
    color: #4e5969;
    &:hover, &.is-active {
      color: #0f6eff;
    }
  }
}
</style>
