var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "equip-manage" },
    [
      _c("TitleModule", { attrs: { title: "管理设备" } }),
      _c("ul", { staticClass: "main" }, [
        _c("li", [
          _c("span", [_vm._v("所属车场")]),
          _c("span", { staticClass: "desc" }, [
            _vm._v(_vm._s(_vm.equipmentDetail.parkName)),
          ]),
        ]),
        _c("li", [
          _c("span", [_vm._v("通道名称")]),
          _c("span", { staticClass: "desc" }, [
            _vm._v(_vm._s(_vm.equipmentDetail.parkChannelName)),
          ]),
        ]),
        _c("li", [
          _c("span", [_vm._v("通道类型")]),
          _c("span", { staticClass: "desc" }, [
            _vm._v(
              _vm._s(
                _vm.channelTypeList.find(
                  (v) => v.code == _vm.equipmentDetail.channelType
                ).desc
              )
            ),
          ]),
        ]),
        _c("li", [
          _c("span", [_vm._v("所属主控机")]),
          _c("span", { staticClass: "desc" }, [
            _vm._v(_vm._s(_vm.equipmentDetail.lightBoxName)),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "add-equip", on: { click: _vm.addEquip } }, [
        _c("i", { staticClass: "el-icon-circle-plus-outline" }),
        _vm._v("添加设备 "),
      ]),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            inline: true,
            "label-width": "120px",
          },
        },
        _vm._l(_vm.formData.equipmentList, function (item, index) {
          return _c(
            "el-card",
            {
              key: index + ";",
              staticClass: "box-card",
              attrs: { shadow: "hover" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "item-title",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("span", [
                    _c("i", { staticClass: "title-icon" }),
                    _vm._v("设备" + _vm._s(index + 1)),
                  ]),
                  _c("i", {
                    staticClass: "el-icon-circle-close",
                    on: {
                      click: function ($event) {
                        return _vm.remove(index)
                      },
                    },
                  }),
                ]
              ),
              _c(
                "div",
                { staticClass: "item" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("searchModule.Equipment_type"),
                        prop: "equipmentList." + index + ".equipmentType",
                        rules: [
                          {
                            required: true,
                            message: "请选择设备类型",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "formWidth",
                          attrs: { placeholder: "请选择" },
                          on: {
                            change: function ($event) {
                              return _vm.equipmentTypeChange(item)
                            },
                          },
                          model: {
                            value: item.equipmentType,
                            callback: function ($$v) {
                              _vm.$set(item, "equipmentType", $$v)
                            },
                            expression: "item.equipmentType",
                          },
                        },
                        _vm._l(
                          _vm.msEquipmentType.filter(
                            (value) => ![1, 13].includes(value.code)
                          ),
                          function (v) {
                            return _c("el-option", {
                              key: v.code,
                              attrs: { label: v.desc, value: v.code },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                  item.equipmentType == 10
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "屏幕",
                            prop: "equipmentList." + index + ".barrierLedType",
                            rules: [
                              {
                                required: true,
                                message: "请选择屏幕类型",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "formWidth",
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: item.barrierLedType,
                                callback: function ($$v) {
                                  _vm.$set(item, "barrierLedType", $$v)
                                },
                                expression: "item.barrierLedType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "LED屏", value: 1 },
                              }),
                              _c("el-option", {
                                attrs: { label: "LED缴费屏", value: 2 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "设备品牌",
                        prop: "equipmentList." + index + ".brandId",
                        rules: [
                          {
                            required: true,
                            message: "请选择设备品牌",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "formWidth",
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: item.brandId,
                            callback: function ($$v) {
                              _vm.$set(item, "brandId", $$v)
                            },
                            expression: "item.brandId",
                          },
                        },
                        _vm._l(
                          _vm.brandList.filter((value) =>
                            value.type.includes(item.equipmentType)
                          ),
                          function (v) {
                            return _c("el-option", {
                              key: v.code,
                              attrs: { label: v.desc, value: v.code },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                  ![5, 14].includes(item.equipmentType)
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "IP地址",
                            prop: "equipmentList." + index + ".ipAddress",
                            rules: [
                              {
                                required: true,
                                message: "请输入IP地址",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "formWidth",
                            attrs: { placeholder: "请输入IP地址" },
                            model: {
                              value: item.ipAddress,
                              callback: function ($$v) {
                                _vm.$set(item, "ipAddress", $$v)
                              },
                              expression: "item.ipAddress",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  ![14].includes(item.equipmentType)
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "端口号",
                            prop: "equipmentList." + index + ".port",
                            rules: [
                              {
                                required: item.equipmentType != 15,
                                message: "请输入端口号",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "formWidth",
                            attrs: {
                              maxlength: "8",
                              placeholder:
                                item.equipmentType != 15
                                  ? "请输入端口号"
                                  : "默认端口29999",
                            },
                            on: {
                              input: ($event) => _vm.timeNumber($event, item),
                            },
                            model: {
                              value: item.port,
                              callback: function ($$v) {
                                _vm.$set(item, "port", _vm._n($$v))
                              },
                              expression: "item.port",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  [14].includes(item.equipmentType)
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "名称",
                            prop: "equipmentList." + index + ".equipmentName",
                            rules: [
                              {
                                required: true,
                                message: "请输入名称",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "formWidth",
                            attrs: {
                              maxlength: "20",
                              placeholder: "请输入名称",
                            },
                            model: {
                              value: item.equipmentName,
                              callback: function ($$v) {
                                _vm.$set(item, "equipmentName", $$v)
                              },
                              expression: "item.equipmentName",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  [14].includes(item.equipmentType)
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "产品类型",
                            prop: "equipmentList." + index + ".productType",
                            rules: [
                              {
                                required: true,
                                message: "请选择产品类型",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "formWidth",
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: item.productType,
                                callback: function ($$v) {
                                  _vm.$set(item, "productType", $$v)
                                },
                                expression: "item.productType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "单目", value: 1 },
                              }),
                              _c("el-option", {
                                attrs: { label: "三目", value: 2 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "SN编号",
                        prop: "equipmentList." + index + ".sn",
                        rules: [
                          {
                            required: true,
                            message: "请输入SN编号",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        staticClass: "formWidth",
                        attrs: { placeholder: "请输入SN编号" },
                        model: {
                          value: item.sn,
                          callback: function ($$v) {
                            _vm.$set(item, "sn", $$v)
                          },
                          expression: "item.sn",
                        },
                      }),
                    ],
                    1
                  ),
                  [2].includes(item.equipmentType)
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("searchModule.type"),
                            prop: "equipmentList." + index + ".gateType",
                            rules: [
                              {
                                required: true,
                                message: "请选择类型",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "formWidth",
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: item.gateType,
                                callback: function ($$v) {
                                  _vm.$set(item, "gateType", $$v)
                                },
                                expression: "item.gateType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "普通", value: 1 },
                              }),
                              _c("el-option", {
                                attrs: { label: "网络", value: 0 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  item.equipmentType == 4
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "闸机主辅",
                            prop: "equipmentList." + index + ".isMain",
                            rules: [
                              {
                                required: true,
                                message: "请选择闸机主辅",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "formWidth",
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: item.isMain,
                                callback: function ($$v) {
                                  _vm.$set(item, "isMain", $$v)
                                },
                                expression: "item.isMain",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "主", value: 1 },
                              }),
                              _c("el-option", {
                                attrs: { label: "辅", value: 0 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  item.equipmentType == 15
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "开闸继电器",
                            prop: "equipmentList." + index + ".openCode",
                            rules: [
                              {
                                required: true,
                                message: "请选择继电器号码",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "formWidth",
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: item.openCode,
                                callback: function ($$v) {
                                  _vm.$set(item, "openCode", $$v)
                                },
                                expression: "item.openCode",
                              },
                            },
                            _vm._l(_vm.relayList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  item.equipmentType == 15
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "关闸继电器",
                            prop: "equipmentList." + index + ".closeCode",
                            rules: [
                              {
                                required: true,
                                message: "请选择继电器号码",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "formWidth",
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: item.closeCode,
                                callback: function ($$v) {
                                  _vm.$set(item, "closeCode", $$v)
                                },
                                expression: "item.closeCode",
                              },
                            },
                            _vm._l(_vm.relayList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        }),
        1
      ),
      _c(
        "div",
        { staticStyle: { "text-align": "center", "margin-top": "10px" } },
        [
          _c("el-button", { on: { click: _vm.resetForm } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.saveLoading },
              on: { click: _vm.saveAndSync },
            },
            [_vm._v(_vm._s(_vm.$t("button.preservation")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }