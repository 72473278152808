<template>
  <div>
    <el-dialog
      :title="berthId ? '编辑泊位' : '添加泊位'"
      :visible.sync="dialogVisible"
      width="510px"
      :close-on-click-modal="false"
      custom-class="dialog"
      :modal-append-to-body="false"
    >
      <el-form ref="berthform" :rules="rules" :model="formInline" label-width="80px">
        <el-form-item :label="$t('searchModule.Berth_number')" prop="berthCode">
          <el-input
            :maxlength="14"
            :disabled="!!berthId"
            v-model="formInline.berthCode"
            class="dialog-width"
            placeholder="请输入泊位编码，支持字母+数字，限长14位"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="!berthId" label="批量添加" prop="batchAddNum">
          <el-input
            :maxlength="3"
            class="dialog-width"
            placeholder="请输入泊位数量，请不要超过车场的总泊位数"
            v-model.number="formInline.batchAddNum"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('searchModule.Service_label')">
          <el-checkbox-group v-model="serviceAttr">
            <el-checkbox label="3">充电泊位</el-checkbox>
            <el-checkbox label="4">残疾人泊位</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="submitData">{{ $t('button.preservation') }}</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    let checkCode = (rule, value, callback) => {
      if (!this.formInline.batchAddNum || this.formInline.batchAddNum == 1) {
        if (/[A-z0-9]/.test(value)) {
          callback();
        } else {
          callback(new Error("支持字母和数字"));
        }
      } else {
        if (/[A-z0-9]*?\d{4}$/.test(value)) {
          callback();
        } else {
          callback(new Error("支持字母和数字且4位数字结尾"));
        }
      }
    };
    let checkNum = (rule, value, callback) => {
      if (!value) {
        return callback();
      }
      if (value != parseInt(value)) {
        return callback(new Error("必须为整数！"));
      }
      if (value < 2) {
        callback(new Error("必须大于1"));
      } else if (value > 100) {
        callback(new Error("不能大于100"));
      } else {
        callback();
      }
    };
    return {
      serviceAttr: [],
      dialogVisible: false,
      berthId: "",
      formInline: {
        parkId: this.$route.query.parkId,
        berthCode: "",
        batchAddNum: "",
        berthDisability: 0,
        charge: 0,
      },
      data2: [],
      rules: {
        berthCode: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
          { validator: checkCode, trigger: "blur" },
        ],
        batchAddNum: [{ validator: checkNum, trigger: "blur" }],
      },
    };
  },
  watch: {},
  components: {},
  methods: {
    open(data) {
      this.berthId = data?.berthId;
      this.serviceAttr = [];
      this.dialogVisible = true;
      this.$refs.berthform && this.$refs.berthform.resetFields();
      this.berthId && this.getDetail();
    },
    getDetail() {
      this.$refs.berthform && this.$refs.berthform.resetFields();
      this.$axios
        .get("/acb/2.0/bacb/berth/detail", {
          data: {
            berthId: this.berthId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            for (let i in this.formInline) {
              this.formInline[i] = res.value[i];
            }
            if (this.formInline.charge == 1) {
              this.serviceAttr.push("3");
            }
            if (this.formInline.berthDisability == 1) {
              this.serviceAttr.push("4");
            }
          }
        });
    },
    submitData() {
      this.$refs.berthform.validate((valid) => {
        if (valid) {
          this.formInline.charge = Number(
            this.serviceAttr.some((v) => {
              return v == 3;
            })
          );
          this.formInline.berthDisability = Number(
            this.serviceAttr.some((v) => {
              return v == 4;
            })
          );
          let url = this.berthId ? "/acb/2.0/bacb/berth/update" : "/acb/2.0/bacb/berth/add";
          this.$axios
            .post(url, {
              data: {
                berthId: this.berthId,
                ...this.formInline,
              },
            })
            .then((res) => {
              if (res.state == 0) {
                this.dialogVisible = false;
                this.$emit("searchData");
              }
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scoped lang="stylus">
/deep/.dialog
  .el-dialog__body
    .el-form-item__label
      min-width auto
    .dialog-width {
      width: 316px;
    }
</style>
