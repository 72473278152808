<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          ref="form"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Equipment_number')" prop="equipmentCode">
                <el-input v-model="formInline.equipmentCode" placeholder="请输入内容"></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Equipment_name')" prop="equipmentName">
                <el-input v-model="formInline.equipmentName" placeholder="请输入内容"></el-input>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  page = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              ><el-button type="info" icon="el-icon-delete" @click="resetForm()"> {{ $t('button.reset') }}</el-button>
            </div>
          </div>
          <div class="col_box_boder"></div>
          <!-- 如果没有下面按钮就不需要 -->
          <div class="col_box h44">
            <div class="col_left">
              <el-button
                type="primary"
                icon="el-icon-plus"
                v-if="!authority.button.add"
                @click="addBerth"
                >{{ $t('button.addto') }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper">
        <el-table v-loading="loading" :data="tableData">
          <el-table-column type="index" :label="$t('list.index')" width="70"></el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
          ></el-table-column>
          <el-table-column label="操作" align="center" width="80">
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="a" v-if="!authority.button.view"
                    >查看</el-dropdown-item
                  >
                  <el-dropdown-item command="b" v-if="!authority.button.edit"
                    >编辑</el-dropdown-item
                  >
                  <el-dropdown-item command="d" v-if="!authority.button.delete"
                    >删除</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <add-berth ref="addBerth" @searchData="searchData"></add-berth>
      <berth-detail ref="berthDetail"></berth-detail>
    </div>
    <!--分页器-->
    <div class="pagerWrapper">
      <div class="block">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import {
  exportExcelNew,
} from "@/common/js/public";
import addBerth from "./add";
import berthDetail from "./Detail";
export default {
  name: "videoEquipmentManage",
  props: {
    authority: {
      type: Object,
      default: () => ({ button: {} }),
    },
  },
  data() {
    return {
      uploadUrl: "/acb/2.0/equipment/import",
      modelUrl: "/acb/2.0/equipment/downloadExcelTemplate",
      uploadData: {
        parkId: this.$route.query.parkId,
      },
      dialogVisible: false,
      hasSelectedList: [],
      equipmentType: [],
      equipmentTypeList: [],
      parkEquipmentType: "",
      traceTag: [],
      brandList: [],
      pName: "",
      index: 0,
      total: 0,
      pageSize: 15,
      page: 1,
      loading: false,
      detailFlag: false,
      tableCols: [
        {
          prop: "equipmentCode",
          label: this.$t("list.Equipment_number"),
          width: "",
        },
        {
          prop: "equipmentName",
          label: this.$t("list.Equipment_name"),
          width: "",
        },
        {
          prop: "equipmentBerthStatus",
          label: "泊位数",
          width: "",
        },
        {
          prop: "equipmentState",
          label: "设备状态",
          width: "",
          formatter: (row, column) => {
            if (row.equipmentState == 1) {
              return <span><i class="on-line"></i>在线</span>
            } else if (row.equipmentState == 2) {
              return <span><i class="on-line un-known"></i>未知</span>
            } else {
              return <span><i class="on-line off-line"></i>离线</span>
            }
          },
        },
        {
          prop: "updatedTime",
          label: this.$t("list.update_time"),
          width: "",
        },
      ],
      tableData: [],
      formInline: {
        parkId: this.$route.query.parkId,
        equipmentCode: "",
        equipmentName: "",
      },
    };
  },
  methods: {
    resetForm() {
      this.$refs.form.resetFields();
    },
    success() {
      this.searchData();
    },
    deleteEquips(id) {
      this.$confirm("删除设备后，会导致泊位无相机数据，确认删除?", this.$t('pop_up.Tips'), {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let url = "/acb/2.0/bacb/equipment/removeEquipment";
          this.$axios
            .post(url, {
              data: {
                equipmentId: id,
              },
            })
            .then((res) => {
              if (res.state == 0) {
                this.$message.success("删除成功！");
                this.page = 1;
                this.searchData();
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    addBerth() {
      this.$refs.addBerth.open();
    },
    handleCommand(cmd, data) {
      if (cmd == "a") {
        this.$refs.berthDetail.getDetail(data);
        this.$refs.berthDetail.open();
      }
      if (cmd == "b") {
        this.$refs.addBerth.open(data.equipmentId);
      }
      if (cmd == "d") {
        this.deleteEquips(data.equipmentId);
      }
    },
    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    // 搜索
    searchData() {
      this.$axios
        .get("/acb/2.0/bacb/equipment/equipmentList", {
          data: {
            pageNum: this.page,
            pageSize: this.pageSize,
            equipmentType: 16,
            ...this.formInline,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            if (res.value) {
              this.tableData = res.value.list;
              this.total = res.value.total * 1;
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    updataState(id, state) {
      if (!id) {
        id = this.hasSelectedList.join(",");
      }
      let url = "/acb/2.0/equipment/updateEquipmentState/" + id + "/" + state;
      this.$axios.post(url).then((res) => {
        if (res.state == 0) {
          this.$alert("操作成功！", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          }).then(() => {
            this.searchData();
          });
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    init() {
      this.$refs.form && this.$refs.form.resetFields();
      this.searchData();
    },
    exportFile() {
      exportExcelNew("/acb/2.0/equipment/export", { parkId: this.$route.query.parkId });
    },
    openMuli() {
      this.$refs.upload.clearFiles();
      this.$refs.upload.open();
    },
  },
  beforeDestroy() {},
  components: {
    addBerth,
    berthDetail,
  },
  created() {},
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
  height 35px
.content
  background #FFFFFF
  overflow hidden
  .on-line {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #09D335;
    margin-right: 8px;
    position: relative;
    top: -2px;
    &.off-line {
      background: #FF0000;
    }
    &.un-known {
      background: blue;
    }
  }
  .pagerWrapper
    text-align right
    margin-top 28px
    font-size 12px
    .export
      font-size 12px
      .iconfont
        margin-right 0
</style>
