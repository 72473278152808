<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          ref="form"
          :model="form"
          label-position="right"
          :rules="rules"
          label-width="130px"
          style="padding: 0"
        >
          <el-form-item label="通讯账号" prop="connAccount">
            <el-input
              v-model="form.connAccount"
              class="formWidth"
              maxlength="16"
              placeholder="请输入通讯账号"
              :disabled="showEdit"
            ></el-input>
            <i class="el-icon-info"></i> <span>必须由字母、数字或字母数字组合，长度6-16位</span>
          </el-form-item>
          <el-form-item label="通讯密码" prop="connPwd">
            <el-input
              v-model="form.connPwd"
              class="formWidth"
              maxlength="16"
              placeholder="请输入通讯密码"
              :disabled="showEdit"
            ></el-input>
            <i class="el-icon-info"></i> <span>必须由字母、数字或字母数字组合，长度6-16位</span>
          </el-form-item>
          <el-form-item label="HTTP服务器地址" prop="httpAddr">
            <el-input
              v-model="form.httpAddr"
              class="formWidth"
              placeholder="请输入HTTP服务器地址"
              :disabled="showEdit"
            ></el-input>
          </el-form-item>
          <el-form-item label="图片服务器地址" prop="imgUrl">
            <el-input
              v-model="form.imgUrl"
              class="formWidth"
              placeholder="请输入图片服务器地址"
              :disabled="showEdit"
            ></el-input>
          </el-form-item>
          <el-form-item label="TCP服务器地址" prop="tcpAddr">
            <el-input
              v-model="form.tcpAddr"
              class="formWidth"
              placeholder="请输入TCP服务器地址"
              :disabled="showEdit"
            ></el-input>
          </el-form-item>
          <el-form-item label="NTP服务地址" prop="ntpUrl">
            <el-input
              v-model="form.ntpUrl"
              class="formWidth"
              placeholder="请输入NTP服务地址"
              :disabled="showEdit"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" v-if="authority.button.edit">
          <el-button type="primary" @click="saveToggle" :loading="saveLoading">{{
            !showEdit ? "保存" : "编辑"
          }}</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { dateFormat } from "../../../../../common/js/public.js";
export default {
  name: "msLight",
  props: {
    authority: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    let checkName = (rule, value, callback) => {
      if (/^[\da-z]{6,16}$/.test(value)) {
        callback();
      } else {
        callback(new Error("请输入正确的账号"));
      }
    };
    let checkCode = (rule, value, callback) => {
      if (/^[\da-z]{6,16}$/.test(value)) {
        callback();
      } else {
        callback(new Error("请输入正确的密码"));
      }
    };
    return {
      configId: "",
      showSave: false,
      form: {
        connAccount: "",
        connPwd: "",
        httpAddr: "",
        imgUrl: "",
        tcpAddr: "",
        ntpUrl: "",
      },
      rules: {
        connAccount: [
          {
            required: true,
            message: "通讯账号为必填项",
            trigger: "blur",
          },
          { validator: checkName, trigger: "blur" },
        ],
        connPwd: [
          {
            required: true,
            message: "通讯密码为必填项",
            trigger: "blur",
          },
          { validator: checkCode, trigger: "blur" },
        ],
        httpAddr: [
          {
            required: true,
            message: "HTTP服务器地址为必填项",
            trigger: "blur",
          },
        ],
        imgUrl: [
          {
            required: true,
            message: "图片服务器地址为必填项",
            trigger: "blur",
          },
        ],
        tcpAddr: [
          {
            required: true,
            message: "TCP服务器地址为必填项",
            trigger: "blur",
          },
        ],
      },
      saveLoading: false,
    };
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    showEdit: {
      get() {
        return !!this.configId && !this.showSave;
      },
      set() {},
    },
  },
  methods: {
    // 搜索
    searchData() {
      this.$axios
        .get("/acb/2.0/bacb/park/configs/" + this.$route.query.parkId)
        .then((res) => {
          if (res.state == 0) {
            this.form = res.value || {};
            this.configId = res.value?.configId;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        })
        .catch((error) => {});
    },
    // 初始化
    init() {
      this.searchData();
    },
    saveToggle() {
      if (this.showSave || !this.configId) {
        this.save();
      } else {
        this.showSave = true;
      }
    },
    // 保存
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          const url = this.configId
            ? "/acb/2.0/bacb/park/configs/update"
            : "/acb/2.0/bacb/park/configs/save";
          this.$axios
            .post(
              url,
              {
                data: {
                  parkId: this.$route.query.parkId,
                  configId: this.configId,
                  ...this.form,
                },
              },
              {
                headers: {
                  "Content-Type": "application/json;charset=UTF-8",
                },
              }
            )
            .then((res) => {
              this.saveLoading = false;
              if (res.state == 0) {
                this.searchData();
                this.showSave = false;
                this.$message.success("通信配置内容保存成功");
              } else {
                this.$alert(res.desc || "通信配置内容保存失败，请重试");
              }
            })
            .catch((error) => {
              this.saveLoading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  overflow: hidden;

  .searchWrapper {
    padding-bottom: 22px;
    color: #606266;
    i {
      vertical-align: middle;
    }
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.el-dropdown-link {
  cursor: pointer;
}
.formWidth {
  margin-right: 15px;
}
</style>
