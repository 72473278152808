<template>
  <div>
    <el-dialog
      :title="equipmentId ? '编辑设备' : '添加设备'"
      :visible.sync="dialogVisible"
      width="703px"
      custom-class="dialog"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :rules="rules" :model="formInline" label-width="80px" label-position="right">
        <el-form-item :label="$t('searchModule.Equipment_number')" prop="equipmentCode">
          <el-input
            :maxlength="14"
            v-model="formInline.equipmentCode"
            placeholder="请输入设备编号，支持字母+数字，限长14位"
            class="dialog-width"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('searchModule.Equipment_name')" prop="equipmentName">
          <el-input
            :maxlength="14"
            v-model="formInline.equipmentName"
            placeholder="请输入设备名称，支持汉字+字母+数字，限长14位"
            class="dialog-width"
          ></el-input>
        </el-form-item>
        <el-form-item label="关联泊位" prop="equipmentBerth">
          <el-transfer
            target-order="push"
            :titles="['未选择', '已选择']"
            filterable
            ref="transfer"
            filter-placeholder="请输入内容"
            :props="{ label: 'berthCode', key: 'berthId' }"
            v-model="formInline.equipmentBerth"
            :data="berthData"
          >
          </el-transfer>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="sure">{{ $t('button.preservation') }}</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    let checkCode = (rule, value, callback) => {
      if (/^[A-Za-z0-9]+$/.test(value)) {
        callback();
      } else {
        callback(new Error("支持字母和数字"));
      }
    };
    let checkName = (rule, value, callback) => {
      if (/^[A-Za-z0-9\u4e00-\u9fa5]+$/.test(value)) {
        callback();
      } else {
        callback(new Error("支持汉字+字母+数字"));
      }
    };
    return {
      dialogVisible: false,
      equipmentId: "",
      rules: {
        equipmentCode: [
          {
            required: true,
            message: "不能为空",
            trigger: "blur",
          },
          { validator: checkCode, trigger: "blur" },
        ],
        equipmentName: [
          {
            required: true,
            message: "不能为空",
            trigger: "blur",
          },
          { validator: checkName, trigger: "blur" },
        ],
      },
      formInline: {
        parkId: this.$route.query.parkId,
        equipmentCode: "",
        equipmentName: "",
        equipmentBerth: [],
      },
      berthData: []
    };
  },
  methods: {
    open(id) {
      this.equipmentId = id;
      // this.$refs.transfer && this.$refs.transfer.clearQuery("left");
      // this.$refs.transfer && this.$refs.transfer.clearQuery("right");
      this.$refs.form && this.$refs.form.resetFields();
      this.formInline.equipmentBerth = [];
      if (id) {
        this.getDetail();
      } else {
        this.berthList();
      }
      this.dialogVisible = true;
    },
    getDetail() {
      let url = "/acb/2.0/bacb/equipment/equipmentDetail/" + this.equipmentId;
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          for (let i in this.formInline) {
            if (i != "equipmentBerth") {
              this.formInline[i] = res.value[i];
            }
          }
          this.filterBerthList = res.value.outBerthList || [];
          if (!res.value.outBerthList) {
            this.formInline.equipmentBerth = [];
          } else {
            this.formInline.equipmentBerth = res.value.outBerthList?.map((v) => v.berthId);
          }
          this.berthList();
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    berthList() {
      let url = "/acb/2.0/bacb/equipment/equipmentBerthList";
      this.$axios
        .get(url, {
          data: {
            parkId: this.$route.query.parkId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.berthData = this.equipmentId && this.filterBerthList ? [...res.value, ...this.filterBerthList] : res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    sure() {
      this.$refs.form.validate((valid) => {
        let data = {
          ...this.formInline,
          equipmentType: 16,
          equipmentId: this.equipmentId,
          equipmentState: !this.equipmentId ? 2 : ''
        };
        data.equipmentBerth = this.formInline.equipmentBerth?.join(",");
        let url = this.equipmentId
          ? "/acb/2.0/bacb/equipment/update"
          : "/acb/2.0/bacb/equipment/add";
        if (valid) {
          this.$axios
            .post(url, {
              data: data,
            })
            .then((res) => {
              if (res.state == 0) {
                this.dialogVisible = false;
                this.$message({
                  type: "success",
                  message: this.equipmentId ? "编辑成功!" : "添加成功!",
                });
                this.$emit("searchData");
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
        } else {
        }
      });
    },
  },
  mounted() {},
};
</script>
<style scoped lang="stylus">
/deep/.dialog
  .el-dialog__body
    .el-form-item__label
      min-width auto
    .dialog-width
      width 564px
</style>
