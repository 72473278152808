<template>
  <div class="equip-manage">
    <TitleModule title="管理设备"></TitleModule>
    <ul class="main">
      <li>
        <span>所属车场</span
        ><span class="desc">{{ equipmentDetail.parkName }}</span>
      </li>
      <li>
        <span>通道名称</span
        ><span class="desc">{{ equipmentDetail.parkChannelName }}</span>
      </li>
      <li>
        <span>通道类型</span
        ><span class="desc">{{
          channelTypeList.find((v) => v.code == equipmentDetail.channelType)
            .desc
        }}</span>
      </li>
      <li>
        <span>所属主控机</span
        ><span class="desc">{{ equipmentDetail.lightBoxName }}</span>
      </li>
    </ul>
    <div class="add-equip" @click="addEquip">
      <i class="el-icon-circle-plus-outline"></i>添加设备
    </div>
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
      :inline="true"
      label-width="120px"
    >
      <el-card
        class="box-card"
        shadow="hover"
        v-for="(item, index) in formData.equipmentList"
        :key="index + ';'"
      >
        <div slot="header" class="item-title">
          <span><i class="title-icon"></i>设备{{ index + 1 }}</span>
          <i class="el-icon-circle-close" @click="remove(index)"></i>
        </div>
        <div class="item">
          <el-form-item
            :label="$t('searchModule.Equipment_type')"
            :prop="'equipmentList.' + index + '.equipmentType'"
            :rules="[
              { required: true, message: '请选择设备类型', trigger: 'blur' },
            ]"
          >
            <el-select
              v-model="item.equipmentType"
              placeholder="请选择"
              class="formWidth"
              @change="equipmentTypeChange(item)"
            >
              <el-option
                :label="v.desc"
                :value="v.code"
                v-for="v in msEquipmentType.filter(
                  (value) => ![1, 13].includes(value.code)
                )"
                :key="v.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="item.equipmentType == 10"
            label="屏幕"
            :prop="'equipmentList.' + index + '.barrierLedType'"
            :rules="[
              { required: true, message: '请选择屏幕类型', trigger: 'blur' },
            ]"
          >
            <el-select
              v-model="item.barrierLedType"
              placeholder="请选择"
              class="formWidth"
            >
              <el-option label="LED屏" :value="1"></el-option>
              <el-option label="LED缴费屏" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="设备品牌"
            :prop="'equipmentList.' + index + '.brandId'"
            :rules="[
              { required: true, message: '请选择设备品牌', trigger: 'blur' },
            ]"
          >
            <el-select
              v-model="item.brandId"
              placeholder="请选择"
              class="formWidth"
            >
              <el-option
                :label="v.desc"
                :value="v.code"
                v-for="v in brandList.filter((value) =>
                  value.type.includes(item.equipmentType)
                )"
                :key="v.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="![5, 14].includes(item.equipmentType)"
            label="IP地址"
            :prop="'equipmentList.' + index + '.ipAddress'"
            :rules="[
              { required: true, message: '请输入IP地址', trigger: 'blur' },
            ]"
          >
            <el-input
              v-model="item.ipAddress"
              class="formWidth"
              placeholder="请输入IP地址"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-if="![14].includes(item.equipmentType)"
            label="端口号"
            :prop="'equipmentList.' + index + '.port'"
            :rules="[
              {
                required: item.equipmentType != 15,
                message: '请输入端口号',
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model.number="item.port"
              maxlength="8"
              class="formWidth"
              @input="($event) => timeNumber($event, item)"
              :placeholder="
                item.equipmentType != 15 ? '请输入端口号' : '默认端口29999'
              "
            ></el-input>
          </el-form-item>
          <el-form-item
            v-if="[14].includes(item.equipmentType)"
            label="名称"
            :prop="'equipmentList.' + index + '.equipmentName'"
            :rules="[
              { required: true, message: '请输入名称', trigger: 'blur' },
            ]"
          >
            <el-input
              v-model="item.equipmentName"
              class="formWidth"
              maxlength="20"
              placeholder="请输入名称"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-if="[14].includes(item.equipmentType)"
            label="产品类型"
            :prop="'equipmentList.' + index + '.productType'"
            :rules="[
              { required: true, message: '请选择产品类型', trigger: 'blur' },
            ]"
          >
            <el-select
              v-model="item.productType"
              placeholder="请选择"
              class="formWidth"
            >
              <el-option label="单目" :value="1"></el-option>
              <el-option label="三目" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="SN编号"
            :prop="'equipmentList.' + index + '.sn'"
            :rules="[
              { required: true, message: '请输入SN编号', trigger: 'blur' },
            ]"
          >
            <el-input
              v-model="item.sn"
              class="formWidth"
              placeholder="请输入SN编号"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-if="[2].includes(item.equipmentType)"
            :label="$t('searchModule.type')"
            :prop="'equipmentList.' + index + '.gateType'"
            :rules="[
              { required: true, message: '请选择类型', trigger: 'blur' },
            ]"
          >
            <el-select
              v-model="item.gateType"
              placeholder="请选择"
              class="formWidth"
            >
              <el-option label="普通" :value="1"></el-option>
              <el-option label="网络" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="item.equipmentType == 4"
            label="闸机主辅"
            :prop="'equipmentList.' + index + '.isMain'"
            :rules="[
              { required: true, message: '请选择闸机主辅', trigger: 'blur' },
            ]"
          >
            <el-select
              v-model="item.isMain"
              placeholder="请选择"
              class="formWidth"
            >
              <el-option label="主" :value="1"></el-option>
              <el-option label="辅" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="item.equipmentType == 15"
            label="开闸继电器"
            :prop="'equipmentList.' + index + '.openCode'"
            :rules="[
              { required: true, message: '请选择继电器号码', trigger: 'blur' },
            ]"
          >
            <el-select
              v-model="item.openCode"
              placeholder="请选择"
              class="formWidth"
            >
              <el-option
                v-for="(item, index) in relayList"
                :label="item.label"
                :value="item.value"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="item.equipmentType == 15"
            label="关闸继电器"
            :prop="'equipmentList.' + index + '.closeCode'"
            :rules="[
              { required: true, message: '请选择继电器号码', trigger: 'blur' },
            ]"
          >
            <el-select
              v-model="item.closeCode"
              placeholder="请选择"
              class="formWidth"
            >
              <el-option
                v-for="(item, index) in relayList"
                :label="item.label"
                :value="item.value"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-card>
    </el-form>
    <div style="text-align: center; margin-top: 10px">
      <el-button @click="resetForm">取 消</el-button>
      <el-button type="primary" @click="saveAndSync" :loading="saveLoading"
        >{{ $t('button.preservation') }}</el-button
      >
    </div>
  </div>
</template>
<script>
import TitleModule from "@/components/titleModule/titleModule";
export default {
  name: "equipmentManage",
  components: {
    TitleModule,
  },
  props: {
    equipmentDetail: {
      type: Object,
      default: () => ({}),
    },
    channelTypeList: {
      type: Array,
      default: () => [],
    },
    msEquipmentType: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      saveLoading: false,
      formData: {
        equipmentList: this.equipmentDetail?.equipmentList || [],
      },
      rules: {},
      brandList: [
        { code: 0, desc: "无", type: [8, 9, 10] },
        { code: 1, desc: "华夏智信", type: [3, 4] },
        { code: 2, desc: "威视智能", type: [3, 6] },
        { code: 3, desc: "德亚闸机", type: [2] },
        { code: 4, desc: "迪虎", type: [5, 7] },
        { code: 5, desc: "海康威视", type: [4] },
        { code: 6, desc: "海康LED", type: [6] },
        { code: 7, desc: "臻识", type: [4] },
        { code: 18, desc: "超级视线", type: [14, 15] },
      ],
      relayList: [
        { value: 1, label: "1号" },
        { value: 2, label: "2号" },
        { value: 3, label: "3号" },
        { value: 4, label: "4号" },
        { value: 5, label: "5号" },
        { value: 6, label: "6号" },
        { value: 7, label: "7号" },
        { value: 8, label: "8号" },
      ],
    };
  },
  watch: {
    equipmentDetail(val) {
      this.formData.equipmentList = val?.equipmentList || [];
    },
  },
  computed: {
    equipmentList() {
      return this.equipmentDetail.equipmentList || [];
    },
  },
  methods: {
    // 设备类型change
    equipmentTypeChange(item) {
      if (item.equipmentType == 14) {
        item.brandId = 18;
      } else {
        item.brandId = "";
      }
    },
    timeNumber(event, item) {
      event = event.replace(/[^\d]/g, "");
      item.port = event;
    },
    addEquip() {
      if (!this.onlyRelay()) {
        this.$alert("当前通道已存在闸机控制器", "", {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return 0;
      }
      this.formData.equipmentList.push({
        equipmentType: 2,
        brandId: "",
        ipAddress: "",
        port: "",
        address: "",
        sn: "",
        gateType: "",
      });
    },
    remove(index) {
      this.formData.equipmentList.splice(index, 1);
    },
    // 取消
    resetForm() {
      this.$refs.form.resetFields();
      this.$emit("searchData", true);
    },
    // 通道添加设备，只能有一个闸机控制器，多个为false，一个为true
    onlyRelay() {
      let flag = 0;
      this.formData.equipmentList.forEach((item) => {
        if (item.equipmentType == 15) {
          flag++;
        }
      });
      if (flag > 1) {
        return false;
      } else {
        return true;
      }
    },
    // 保存
    saveAndSync() {
      if (!this.onlyRelay()) {
        this.$alert("只能添加一个闸机控制器", "", {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return 0;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          // equipmentType==15,port默认值设置为29999
          // if (this.formData.equipmentList[0].port === "") {
          //   this.formData.equipmentList[0].port = 29999;
          // }
          this.formData.equipmentList.forEach((item) => {
            if (item.port == "") {
              item.port = "29999";
            }
          });
          console.log(this.formData.equipmentList, "baocun");

          this.saveLoading = true;
          this.$axios
            .post(
              "/acb/2.0/bacb/park/lightbox/channel/equipments/save/batch",
              {
                data: {
                  parkChannelId: this.equipmentDetail.parkChannelId,
                  equipments: this.formData.equipmentList,
                },
              },
              {
                headers: {
                  "Content-Type": "application/json;charset=UTF-8",
                },
              }
            )
            .then((res) => {
              this.saveLoading = false;
              if (res.state == 0) {
                this.$emit("searchData", true);
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            })
            .catch((error) => {
              this.saveLoading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.equip-manage {
  margin: 18px 16px 18px 15px;
  flex: 1;

  .main {
    background: rgba(154, 164, 179, 0.1);
    border-radius: 8px 8px 8px 8px;
    padding: 16px 12px;

    li {
      padding: 12px;
      font-size: 12px;
      color: #626EA3;
      line-height: 22px;
      display: flex;

      span:first-child {
        width: 70px;
        text-align: right;
      }

      .desc {
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        margin-left: 24px;
        flex: 1;
      }
    }
  }

  .add-equip {
    height: 38px;
    border-radius: 8px;
    border: 1px dashed #527BFF;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #527BFF;
    margin: 16px 0;
    cursor: pointer;

    &:hover {
      background: #ecf5ff;
    }

    i {
      margin-right: 6px;
    }
  }

  .box-card {
    margin-bottom: 16px;

    .title-icon {
      width: 6px;
      height: 6px;
      border-radius: 6px;
      background: #527BFF;
      margin-right: 12px;
      display: inline-block;
      vertical-align: middle;
    }

    .formWidth {
      width: 200px;
    }

    .item-title {
      display: flex;
      justify-content: space-between;

      i {
        font-size: 17px;
        cursor: pointer;

        &:hover {
          color: #527bff;
        }
      }
    }
  }
}
</style>
