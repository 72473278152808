<template>
  <div class="page1">
    <el-dialog
      title="添加收费员"
      :visible.sync="dialogVisible"
      width="1000px"
      :close-on-click-modal="false"
      custom-class="dialog"
      @close="resetForm"
    >
      <!--主体内容-->
      <div class="content">
        <h2 class="title">
          <div class="title_icon"></div>
          <!-- {{isEdit ? '修改' : '添加'}}收费员 -->
          基础信息
        </h2>
        <!--搜索条件区域-->
        <div class="searchWrapper" @keydown.enter.prevent="submitData">
          <el-form
            label-position="right"
            label-width="110px"
            :model="formInline"
            style="width: 330px"
            :rules="rules"
            ref="form"
          >
            <el-form-item :label="$t('searchModule.full_name')" prop="mcUserName">
              <el-input
                :maxlength="10"
                v-model.trim="formInline.mcUserName"
                placeholder="请输入姓名"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('searchModule.sex')" prop="sex">
              <el-radio-group
                v-model.trim="formInline.sex"
                filterable
                placeholder=""
              >
                <el-radio :label="1">男</el-radio>
                <el-radio :label="0">女</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item :label="$t('searchModule.account_number')" prop="account">
              <el-input
                :maxlength="20"
                :disabled="isEdit"
                v-model.trim="formInline.account"
                placeholder="输入账号"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('searchModule.phone_number')" prop="mobile">
              <el-input
                :maxlength="11"
                v-model.trim="formInline.mobile"
                placeholder="输入手机号码"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Department_to')" prop="departmentId">
              <!-- <el-form-item :label="$t('searchModule.Department_to')"> -->
              <el-cascader
                :show-all-levels="false"
                style="width: 220px"
                :options="options"
                v-model="formInline.departmentId"
                :props="{
                  emitPath: false,
                  checkStrictly: true,
                  value: 'deptId',
                  label: 'deptName',
                  children: 'childNodes',
                }"
                clearable
              ></el-cascader>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Merchant_Name')" prop="operationId">
              <el-select
                @change="queryParkList(1)"
                v-model.trim="formInline.operationId"
                filterable
                size="15"
                :disabled="true"
                placeholder="请选择"
              >
                <!-- <el-option label="全部" value=""></el-option> -->
                <el-option
                  :label="value.operationName"
                  :value="value.operationId"
                  :key="value.operationId"
                  v-for="value in roleListName"
                ></el-option>
              </el-select>
            </el-form-item>
            <h2 class="title1 title">
              <div class="title_icon"></div>
              选择停车场
            </h2>
            <el-form-item
              class="parkc"
              :label="$t('searchModule.Choose_parking_lot')"
              style="width: 705px"
              prop="parkIds"
            >
              <el-transfer
                target-order="push"
                :titles="['未选择', '已选择']"
                :props="{
                  key: 'parkId',
                  label: 'parkName',
                }"
                filter-placeholder="请输入城市拼音"
                v-model="selected"
                :data="parkList"
              >
              </el-transfer>
            </el-form-item>
          </el-form>
          <div style="text-align: left; margin-left: 8.5%; margin-top: 40px">
            <el-button type="primary" @click="submitData" style="width: 88px"
              >提交</el-button
            >
            <el-button
              type=""
              @click="dialogVisible = false"
              style="width: 88px"
              >取消</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
    <script>
// import //  dateFormat
// "@/common/js/public.js";
export default {
  name: "tollgathererManageAdd",
  data() {
    let checkCode = (rule, value, callback) => {
      if (/[\u4E00-\u9FA5]/.test(value)) {
        return callback(new Error("员工编号不能存在汉字"));
      }
      if (value) {
        if (value.length < 3 || value.length > 16) {
          return callback(new Error("员工编号长度需在3-16个字符之内"));
        }
        if (/[a-zA-Z0-9]{2,50}/.test(value)) {
          callback();
          // getDt(opt);
        } else {
          callback(new Error("员工编号包含字母和数字"));
        }
      } else {
        callback(new Error("员工编号不能为空"));
      }
    };
    let checkPDAccount = (rule, value, callback) => {
      if (value) {
        if (/\w{3,16}/.test(value) && !/^\d{3,16}$/.test(value)) {
          callback();
        } else {
          callback(new Error("账号3-16个字符且不能为纯数字"));
        }
      } else {
        callback(new Error("账号不能为空"));
      }
    };
    let setBirth = (rule, value, callback) => {
      let cur = new Date().getFullYear();
      if (parseInt(value) != value) {
        callback(new Error("出生年份格式不正确"));
      } else if (value > cur) {
        callback(new Error("出生年份不能大于当前年份"));
      } else if (!/^\d{4}$/.test(value)) {
        callback(new Error("出生年份必须为4位数"));
      } else {
        callback();
      }
    };
    let checkName = (rule, value, callback) => {
      let len = value.length;
      for (let i = 0; i < len; i++) {
        if (value.charCodeAt(i) < 10000) {
          callback(new Error("姓名必须为汉字"));
          return;
        }
      }
      callback();
    };
    let checkPhone = (rule, value, callback) => {
      if (!/^\d{11}$/.test(value)) {
        callback(new Error("手机号格式不正确"));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      roleListName: [],
      options: [],
      selected: [],
      parkList: [],
      isEdit: false,
      formInline: {
        departmentId: "",
        mcUserName: "",
        sex: 1,
        mobile: "",
        account: "",
        parkIds: [],
        operationId: this.details.operationId,
      },
      rules: {
        mcUserName: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
          { validator: checkName, trigger: "blur" },
        ],
        sex: [
          {
            required: true,
            message: "请选择性别",
            trigger: "blur",
          },
        ],
        role: [
          {
            required: true,
            message: "请选择角色",
            trigger: "change",
          },
        ],
        departmentId: [
          {
            required: true,
            message: "请选择部门",
            trigger: "change",
          },
        ],
        birthday: [
          {
            required: true,
            message: "请选择年份",
            trigger: "blur",
          },
          { validator: setBirth, trigger: "blur" },
        ],

        mobile: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
          { validator: checkPhone, trigger: "blur" },
        ],
        account: [
          {
            required: true,
            message: "请输入账号",
            trigger: "blur",
          },
          { validator: checkPDAccount, trigger: "blur" },
        ],

        operationId: [
          {
            required: true,
            message: "必填",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    resetForm() {},
    /* 商户名称的下拉框 运营商 */
    getRoleList() {
      this.$axios
        .get("/acb/2.0/operation/nameList", {
          data: {},
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.roleListName = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 查询车场列表
    queryParkList(tag) {
      let url = "/acb/2.0/bacb/systems/loginUser/getParkName";
      this.$axios
        .get(url, {
          data: {
            page: 0,
            pageSize: 0,
            slaveRelations: "0,1",
            operationId: this.formInline.operationId,
            // bizLogicType: "exclude_lightbox",
          },
        })
        .then((res) => {
          if (res.state != 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            return;
          }
          this.parkList = res.value;
          this.parkList.forEach((item) => {
            item.disabled = true;
          });
          if (tag == 1) {
            this.selected = [];
          }
        });
    },
    // 获取部门
    getDeptTreeByCurrentUser() {
      this.$axios.get("/acb/2.0/dept/getByCurrentUser").then((res) => {
        if (res.state == 0) {
          this.options = [res.value];
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    addList() {
      let url = this.isEdit
        ? "/acb/2.0/bacb/mcUser/update"
        : "/acb/2.0/bacb/mcUser/add";
      let method = "post";
      this.$axios[method](url, {
        data: this.formInline,
      }).then((res) => {
        if (res.state != 0) {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return;
        }
        if (res.state == 0) {
          this.dialogVisible = false;
          this.$emit("searchData");
          this.$message({ message: res.desc, type: "success" });
        }
      });
    },

    // 编辑赋值
    workDetail() {
      let url = "/acb/2.0/bacb/mcUser/getById";
      this.$axios
        .get(url, {
          data: {
            mcUserId: this.formInline.mcUserId,
          },
        })
        .then((res) => {
          if (res.state != 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            return;
          }
          for (let i in this.formInline) {
            // console.log("i==>", i);
            this.formInline[i] = res.value[i];
          }
          this.queryParkList(); // 获取车场
          this.selected = this.formInline["parkIds"];
        });
    },
    submitData() {
      this.formInline.parkIds = this.selected.join(",");
      this.$refs["form"].validate((valid) => {
        // console.log("");
        if (valid) {
          this.addList();
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
    open(mcUserId, isEdit) {
      this.isEdit = isEdit;
      // console.log(mcUserId, "mcUserId---------");
      this.dialogVisible = true;
      this.getRoleList(); // 获取商户
      // console.log("this.$route.query.=>", this.$route.query);
      if (this.isEdit) {
        this.formInline.mcUserId = mcUserId;
        this.isEdit = true;
        this.workDetail();
      } else {
        this.formInline = {
          departmentId: "",
          mcUserName: "",
          sex: 1,
          mobile: "",
          account: "",
          parkIds: [this.details.parkId],
          operationId: this.details.operationId,
        };
        this.selected = this.formInline["parkIds"];
      }
      this.getDeptTreeByCurrentUser(); // 获取部门
      this.queryParkList(); // 获取车场
    },
  },
  props: {
    details: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  components: {},
  created() {
    // this.getRoleList(); // 获取商户
    // // console.log("this.$route.query.=>", this.$route.query);
    // if (this.$route.query.mcUserId) {
    //   this.formInline.mcUserId = this.$route.query.mcUserId;
    //   this.isEdit = true;
    //   this.workDetail();
    // } else {
    // }
    // this.getDeptTreeByCurrentUser(); // 获取部门
    // this.queryParkList(); // 获取车场
  },
  mounted() {},
  computed: {},
};
</script>
    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <style scoped>
.parkc >>> .el-form-item__content {
  line-height: 1px !important;
}
.parkc >>> .el-input__inner {
  min-width: 172px !important;
  padding-left: 26px !important;
}
.parkc >>> .el-input {
  min-width: 172px !important;
}
.parkc >>> .el-input__prefix {
  left: 0 !important;
  top: 0 !important;
}
/deep/ .el-form-item {
  margin-bottom: 22px !important;
}
.parkc {
  height: initial !important;
}
</style>
    <style scoped lang="stylus" rel="stylesheet/stylus">
    .breadcrumbAddRoadside {
      height: 35px;
    }

    .content {
      background: #FFFFFF;
      overflow: hidden;
      border: 1px solid #C0CCDA;
      border-radius: 4px;
      padding: 20px;
    }
</style>
    <style scoped>
.chosePark {
  float: right;
  margin-right: -70px;
  margin-top: -52px;
}
.title1 {
  /* margin-left: -20px; */
  margin: 20px 0 20px -20px;
}
.searchWrapper {
  padding-top: 0px !important;
}
</style>
