<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper">
        <h2 class="title">
          <div class="title_icon"></div>
          导航图
        </h2>
        <el-input
          placeholder="输入关键字进行过滤"
          v-model="filterText"
          suffix-icon="el-icon-search"
        >
        </el-input>
        <el-tree
          class="filter-tree"
          :data="navData"
          :props="defaultProps"
          default-expand-all
          node-key="id"
          :expand-on-click-node="false"
          check-on-click-node
          :filter-node-method="filterNode"
          @node-click="handleNodeClick"
          ref="tree"
        >
          <div class="custom-tree-node" slot-scope="{ node, data }">
            <div @click.stop="getEquipmentDetail(data)" class="nav-node">
              <div class="nav-item">{{ node.label }}</div>
              <div class="warning-tip" :class="data.offlineTime > 5 ? 'red' : 'green'">
                <img v-if="!data.state" src="@/assets/img/warning-tip.png" />
                <span v-if="data.offlineTime">{{ data.offlineTime }}min</span>
              </div>
            </div>
          </div>
        </el-tree>
      </div>
      <!--列表区域-->
      <div class="tableWrapper" v-if="showFlag">
        <div class="table-btns">
          <el-button
            v-if="authority.button.addzkj"
            type="primary"
            size="small"
            @click="$refs.add.open()"
            >添加主控机</el-button
          >
          <el-button
            v-if="authority.button.addtd"
            type="primary"
            size="small"
            @click="$refs.access.open()"
            >添加通道</el-button
          >
        </div>
        <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="lightBoxList"
        >
          <el-table-column type="index" :label="$t('list.index')" width="70" align="center">
          </el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
          >
          </el-table-column>
          <el-table-column label="通讯状态" align="center">
            <template slot-scope="{ row }">
              <div class="contact-status">
                <i :class="row.state == 1 ? 'green' : 'red'"></i>
                {{ row.state == 1 ? "在线" : "离线" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="80">
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="authority.button.editzkj" command="a"
                    >编辑
                  </el-dropdown-item>
                  <el-dropdown-item v-if="authority.button.deletezkj" command="b"
                    >删除
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <div class="table-btns">
          <el-button
            v-if="authority.button.syncthird"
            :disabled="parkStatus == 1"
            type="primary"
            size="small"
            @click="sync(1)"
            >三方同步</el-button
          >
        </div>
        <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="channelList"
        >
          <el-table-column type="index" :label="$t('list.index')" width="70" align="center">
          </el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableColsAccess"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
          >
          </el-table-column>
          <el-table-column :label="$t('searchModule.Equipment_status')" align="center">
            <template slot-scope="{ row }">
              <div class="contact-status">
                <i :class="row.state == 1 ? 'green' : 'red'"></i>
                {{
                  row.state === 1 ? "在线" : row.state === 0 ? "离线" : "待同步"
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="80">
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="authority.button.edittd" command="c"
                    >编辑</el-dropdown-item
                  >
                  <el-dropdown-item command="e">设备管理</el-dropdown-item>
                  <el-dropdown-item v-if="authority.button.deletetd" command="d"
                    >删除</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <div class="table-btns">
          <el-button
            v-if="authority.button.syncdevice"
            :disabled="parkStatus == 1"
            type="primary"
            size="small"
            @click="sync(2)"
            >设备同步</el-button
          >
        </div>
        <add ref="add" @searchData="searchData" :parkId="details.parkId"></add>
        <access
          ref="access"
          @searchData="searchData"
          :lightBoxList="lightBoxList"
          :channelTypeList="channelTypeList"
        ></access>
      </div>
      <manage
        v-else
        ref="equipmentManage"
        :equipmentDetail="equipmentDetail"
        :channelTypeList="channelTypeList"
        :msEquipmentType="msEquipmentType"
        @searchData="searchData"
      ></manage>
    </div>
  </div>
</template>
<script>
import Add from "./add";
import Access from "./access";
import Manage from "./manage";
import { dateFormat } from "../../../../../common/js/public.js";
export default {
  name: "equipment",
  props: {
    authority: {
      type: Object,
      default: function () {
        return {
          button: {},
        };
      },
    },
    // 停车场详情
    details: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  components: {
    Add,
    Access,
    Manage,
  },
  data() {
    return {
      index: 0,
      pageSize: 15,
      page: 1,
      loading: false,
      showFlag: true,
      channelTypeList: [],
      msEquipmentType: [],
      equipmentDetail: null,
      parkStatus: "",
      tableCols: [
        {
          prop: "name",
          label: "主控机名称",
          width: "",
        },
        {
          prop: "sn",
          label: "主控板编码",
          width: "",
        },
        {
          prop: "channelsCount",
          label: "通道数量",
          width: "",
        },
        {
          prop: "eocState",
          label: "三方同步状态",
          formatter: (row, column, cellValue, index) => {
            return ["待同步", "连接EOC失败", "连接本地失败", "成功"][cellValue];
          },
        },
      ],
      tableColsAccess: [
        {
          prop: "parkChannelName",
          label: this.$t("list.Channel_name"),
          width: "",
        },
        {
          prop: "channelType",
          label: "通道类型",
          formatter: (row, column, cellValue, index) => {
            return this.channelTypeList?.find((v) => v.code == cellValue).desc;
          },
        },
        {
          prop: "lightBoxName",
          label: "关联主控机",
          width: "",
        },
        {
          prop: "equipmentsCount",
          label: "设备数量",
          width: "",
        },
      ],
      lightBoxList: [],
      channelList: [],
      formInline: {
        parkChannelName: "",
      },
      filterText: "",
      navData: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
    };
  },
  created() {
    // 获取通道类型枚举
    this.getList();
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  computed: {},
  methods: {
    sync(type) {
      let saveLoading = this.$loading({
        lock: true,
        text: "同步中,请稍后...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.6)",
      });
      const url =
        type == 1
          ? `/acb/2.0/bacb/eoc/sync/park/${this.details.parkId}`
          : `/acb/2.0/bacb/local/sync/park/${this.details.parkId}/topology`;
      this.$axios
        .get(url)
        .then((res) => {
          if (res.state == 0) {
            this.$alert("同步已完成，请查看同步结果。", "同步完成", {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            this.searchData();
          } else {
            this.$alert(res.desc, "同步失败", {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        })
        .finally(() => {
          saveLoading.close();
        });
    },
    // 操作菜单项
    handleCommand(cmd, data) {
      if (cmd == "a") {
        // 编辑主控机
        this.$refs.add.open(data);
      } else if (cmd == "b") {
        // 删除主控机
        this.deleteparkChannel(cmd, data.lightBoxId);
      } else if (cmd == "c") {
        // 编辑通道
        this.$refs.access.open(data);
      } else if (cmd == "d") {
        // 删除通道
        this.deleteparkChannel(cmd, data.parkChannelId);
      } else if (cmd == "e") {
        this.getEquipmentDetail(data);
      }
    },
    // 获取管理设备详情
    getEquipmentDetail(data) {
      if (data.level && data.level !== 2) {
        return;
      }
      this.$axios
        .get(
          `/acb/2.0/bacb/park/lightbox/channels/${
            data.parkChannelId || data.id
          }/equipment`
        )
        .then((res) => {
          if (res.state == 0) {
            // 进设备管理
            if (this.$refs.equipmentManage) {
              this.$refs.equipmentManage.$;
            }
            this.equipmentDetail = res.value || {};
            this.showFlag = false;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 删除
    deleteparkChannel(cmd, id) {
      const url =
        cmd == "b"
          ? `/acb/2.0/bacb/park/lightboxes/delete/${id}`
          : `/acb/2.0/bacb/park/lightbox/channels/delete/${id}`;
      const title = cmd == "b" ? `确认后该主控机将被删除` : `确定删除该通道及相关设备信息？`;
      const tip = cmd == "b" ? `确定删除主控机` : `确定删除通道`;
      const info = cmd == "b" ? `删除主控机成功。` : `删除通道成功。`;
      this.$confirm(title, tip, {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .post(url, {
              headers: {
                "Content-Type": "application/json;charset=UTF-8",
              },
            })
            .then((res) => {
              if (res.state == 0) {
                this.$message({
                  type: "success",
                  message: info,
                });
                this.searchData();
              } else {
                this.$message({
                  type: "error",
                  message: cmd == "b" ? "删除主控机失败，请重试。" : "删除通道失败，请重试。",
                });
              }
            });
        })
        .catch(() => {});
    },
    // 设备管理列表包含主控机和通道
    searchData(flag) {
      this.lightBoxData();
      this.channelData();
      this.getNavList();
      // 获取停车场状态
      this.getParkDetail(this.details.parkId);
      if (flag) {
        this.showFlag = true;
      }
    },
    getParkDetail(parkId) {
      let url = "/acb/2.0/bacb/park/getParkVOById/" + parkId;
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          this.parkStatus = res.value.commercialStatus;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    // 通道数据
    channelData() {
      this.loading = true;
      this.$axios
        .get("/acb/2.0/bacb/park/lightbox/channels/" + this.details.parkId)
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            if (res.value) {
              this.channelList = res.value || [];
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    // 获取主控机列表
    lightBoxData() {
      this.lightLoading = true;
      this.$axios
        .get("/acb/2.0/bacb/park/lightboxes/" + this.details.parkId)
        .then((res) => {
          this.lightLoading = false;
          if (res.state == 0) {
            if (res.value) {
              this.lightBoxList = res.value || [];
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        })
        .catch((error) => {
          this.lightLoading = false;
        });
    },
    // 获取导航图
    getNavList() {
      this.$axios
        .get(
          "/acb/2.0/bacb/park/lightbox/channel/equipments/tree/" +
            this.details.parkId
        )
        .then((res) => {
          if (res.state == 0) {
            if (res.value) {
              this.navData = res.value?.trees || [];
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        })
        .catch((error) => {});
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name?.indexOf(value) !== -1;
    },
    handleNodeClick(data) {
      console.log(1, data);
    },
    init() {
      this.searchData();
    },
    // 获取通道类型下拉列表
    getList() {
      this.$axios.get("/acb/2.0/bacb/common/getOptions").then((res) => {
        this.channelTypeList = res.value?.parkChannelType || [];
        this.msEquipmentType = res.value?.msEquipmentType || [];
        // this.msEquipmentType = [
        //   {
        //     code: 1,
        //     desc: "MS设备",
        //   },
        //   {
        //     code: 2,
        //     desc: "闸机",
        //   },
        //   {
        //     code: 3,
        //     desc: "LED屏",
        //   },
        //   {
        //     code: 4,
        //     desc: "识别一体机",
        //   },
        //   {
        //     code: 5,
        //     desc: "IP对讲",
        //   },
        //   {
        //     code: 6,
        //     desc: "LED缴费屏",
        //   },
        //   {
        //     code: 7,
        //     desc: "扫码器",
        //   },
        //   {
        //     code: 8,
        //     desc: "余位屏",
        //   },
        //   {
        //     code: 9,
        //     desc: "ETC天线",
        //   },
        //   {
        //     code: 10,
        //     desc: "道闸一体机",
        //   },
        //   {
        //     code: 11,
        //     desc: "闸机控制器",
        //   },
        //   {
        //     code: 13,
        //     desc: "智能控制板",
        //   },
        //   {
        //     code: 14,
        //     desc: "车道感知机器人",
        //   },
        // ];
      });
    },
  },
  beforeDestroy() {
    sessionStorage.searchData = JSON.stringify(this.formInline);
    sessionStorage.searchFlag = 1;
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  display: flex;
  overflow: hidden;
  background: #ffffff;

  .searchWrapper {
    overflow: hidden;
    padding: 20px;
    width: 225px;
    min-width: 225px;
    border-right: 1px solid #e6e6e6;

    .custom-tree-node {
      width: 89%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #333B5E;

      .nav-node {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #333B5E;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 225px;
          height: 26px;
        }

        .nav-item {
          font-size: 14px;
          flex: 1;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
        }

        .warning-tip {
          display: flex;
          justify-content: flex-end;

          img {
            width: 14px;
            height: 14px;
            margin-right: 3px;
          }

          &.green {
            color: #3DCEA8;
          }

          &.red {
            color: #FF2E00;
          }
        }
      }
    }

    ::v-deep .el-tree-node__content {
      margin-bottom: 14px;
    }

    .el-input {
      margin-bottom: 20px;
    }
  }

  .tableWrapper {
    width: 100%;
    .table-btns {
      display: flex;
      justify-content: flex-end;
      padding: 20px 20px 20px 0;
    }

    .contact-status {
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        width: 8px;
        height: 8px;
        border-radius: 8px;
        margin-right: 8px;

        &.green {
          background: #1BC99F;
        }

        &.red {
          background: #F6725A;
        }
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.el-dropdown-link {
  cursor: pointer;
}

.el-message-box__message {
  ::v-deep .loading-text {
    text-align: center !important;

    span {
      display: block;
    }
  }
}
</style>
