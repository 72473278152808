var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page1" }, [
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        {
          staticClass: "searchWrapper",
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              $event.preventDefault()
              return _vm.searchData.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { padding: "0" },
              attrs: {
                model: _vm.form,
                "label-position": "right",
                rules: _vm.rules,
                "label-width": "130px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "通讯账号", prop: "connAccount" } },
                [
                  _c("el-input", {
                    staticClass: "formWidth",
                    attrs: {
                      maxlength: "16",
                      placeholder: "请输入通讯账号",
                      disabled: _vm.showEdit,
                    },
                    model: {
                      value: _vm.form.connAccount,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "connAccount", $$v)
                      },
                      expression: "form.connAccount",
                    },
                  }),
                  _c("i", { staticClass: "el-icon-info" }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v("必须由字母、数字或字母数字组合，长度6-16位"),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "通讯密码", prop: "connPwd" } },
                [
                  _c("el-input", {
                    staticClass: "formWidth",
                    attrs: {
                      maxlength: "16",
                      placeholder: "请输入通讯密码",
                      disabled: _vm.showEdit,
                    },
                    model: {
                      value: _vm.form.connPwd,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "connPwd", $$v)
                      },
                      expression: "form.connPwd",
                    },
                  }),
                  _c("i", { staticClass: "el-icon-info" }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v("必须由字母、数字或字母数字组合，长度6-16位"),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "HTTP服务器地址", prop: "httpAddr" } },
                [
                  _c("el-input", {
                    staticClass: "formWidth",
                    attrs: {
                      placeholder: "请输入HTTP服务器地址",
                      disabled: _vm.showEdit,
                    },
                    model: {
                      value: _vm.form.httpAddr,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "httpAddr", $$v)
                      },
                      expression: "form.httpAddr",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "图片服务器地址", prop: "imgUrl" } },
                [
                  _c("el-input", {
                    staticClass: "formWidth",
                    attrs: {
                      placeholder: "请输入图片服务器地址",
                      disabled: _vm.showEdit,
                    },
                    model: {
                      value: _vm.form.imgUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "imgUrl", $$v)
                      },
                      expression: "form.imgUrl",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "TCP服务器地址", prop: "tcpAddr" } },
                [
                  _c("el-input", {
                    staticClass: "formWidth",
                    attrs: {
                      placeholder: "请输入TCP服务器地址",
                      disabled: _vm.showEdit,
                    },
                    model: {
                      value: _vm.form.tcpAddr,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "tcpAddr", $$v)
                      },
                      expression: "form.tcpAddr",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "NTP服务地址", prop: "ntpUrl" } },
                [
                  _c("el-input", {
                    staticClass: "formWidth",
                    attrs: {
                      placeholder: "请输入NTP服务地址",
                      disabled: _vm.showEdit,
                    },
                    model: {
                      value: _vm.form.ntpUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "ntpUrl", $$v)
                      },
                      expression: "form.ntpUrl",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.authority.button.edit
            ? _c(
                "div",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.saveLoading },
                      on: { click: _vm.saveToggle },
                    },
                    [_vm._v(_vm._s(!_vm.showEdit ? "保存" : "编辑"))]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }