<template>
  <div>
    <el-dialog title="查看泊位" :visible.sync="dialogVisible" width="500px" custom-class="dialog">
      <el-form ref="berthform" :model="formInline" label-width="90px">
        <el-form-item label="泊位编号：" prop="berthCode">
          <span>{{ formInline.berthCode }}</span>
        </el-form-item>
        <el-form-item label="服务标签：">
          <el-checkbox-group v-model="serviceAttr">
            <el-checkbox label="3" disabled>充电泊位</el-checkbox>
            <el-checkbox label="4" disabled>残疾人泊位</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="dialogVisible = false">返回</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      lng: "",
      serviceAttr: [],
      dialogVisible: false,
      formInline: {
        parkId: this.$route.query.parkId,
        feeList: [{ feeCode: 123, feeName: "无" }],
        berthCode: "",
        batchAddNum: "",
        berthQuality: "",
        berthType: "",
        electricBerth: 0,
        serviceTypeShare: "0",
        serviceTypeTide: "0",
      },
      data2: [],
    };
  },
  props: ["qualityState", "berthType"],
  methods: {
    open() {
      this.dialogVisible = true;
      this.serviceAttr = [];
    },
    getDetail({ berthId }) {
      this.lng = "";
      this.$axios
        .get("/acb/2.0/bacb/berth/detail", {
          data: {
            berthId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.formInline = res.value;
            if (this.formInline.charge == 1) {
              this.serviceAttr.push("3");
            }
            if (this.formInline.berthDisability == 1) {
              this.serviceAttr.push("4");
            }
          }
        });
    },
  },
};
</script>
<style lang="stylus" scoped>
/deep/.dialog
  .el-dialog__body
    .el-form-item__label
      min-width auto
</style>
