<template>
  <div>
    <el-dialog
      :title="!lightBoxId ? '添加主控机' : '编辑主控机'"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="500px"
      custom-class="dialog"
      @close="resetForm"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="120px" style="padding: 0">
        <el-form-item label="主控板编码" prop="sn">
          <el-input
            v-model="form.sn"
            :disabled="eocState == 3"
            class="formWidth"
            maxlength="20"
            placeholder="请输入主控板编码"
          ></el-input>
        </el-form-item>
        <el-form-item label="监控板编码" prop="monitorBoardSn">
          <el-input
            v-model="form.monitorBoardSn"
            :disabled="eocState == 3"
            class="formWidth"
            maxlength="20"
            placeholder="请输入监控板编码"
          ></el-input>
        </el-form-item>
        <el-form-item label="主控机名称" prop="name">
          <el-input
            v-model="form.name"
            class="formWidth"
            maxlength="20"
            placeholder="请输入主控机名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="品牌" prop="brandId" required>
          <el-select v-model="form.brandId" placeholder="请选择" class="formWidth">
            <el-option
              :label="item.desc"
              :value="item.code"
              v-for="item in brandGroup"
              :key="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="resetForm">返回</el-button>
        <el-button type="primary" @click="save" :loading="saveLoading">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    let checkName = (rule, value, callback) => {
      if (/^[A-Za-z0-9]+$/.test(value)) {
        callback();
      } else {
        callback(new Error("请正确输入主控板编码"));
      }
    };
    let checkCode = (rule, value, callback) => {
      if (/^[A-Za-z0-9]+$/.test(value)) {
        callback();
      } else {
        callback(new Error("请正确输入设备编码"));
      }
    };
    let checkMain = (rule, value, callback) => {
      if (/^[A-Za-z0-9\u4e00-\u9fa5]+$/.test(value)) {
        callback();
      } else {
        callback(new Error("请正确输入主控机名称"));
      }
    };
    return {
      dialogVisible: false,
      lightBoxId: "",
      eocState: "",
      brandGroup: [],
      form: {
        sn: "",
        name: "",
        brandId: "1",
        monitorBoardSn: "",
      },
      rules: {
        sn: [
          {
            required: true,
            message: "主控板编码必填",
            trigger: "blur",
          },
          { validator: checkName, trigger: "blur" },
        ],
        name: [
          {
            required: true,
            message: "主控机名称必填",
            trigger: "blur",
          },
          { validator: checkMain, trigger: "blur" },
        ],
      },
      saveLoading: false,
    };
  },
  methods: {
    // 打开窗口
    open(data) {
      this.dialogVisible = true;
      this.lightBoxId = data?.lightBoxId;
      this.eocState = data?.eocState;
      data &&
        Object.keys(this.form).forEach((v) => {
          this.form[v] = data[v];
        });
    },
    // 保存
    save() {
      const url = this.lightBoxId
        ? "/acb/2.0/bacb/park/lightboxes/update"
        : "/acb/2.0/bacb/park/lightboxes/save";
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          this.$axios
            .post(
              url,
              {
                data: {
                  parkId: this.$route.query.parkId,
                  lightBoxId: this.lightBoxId,
                  ...this.form,
                },
              },
              {
                headers: {
                  "Content-Type": "application/json;charset=UTF-8",
                },
              }
            )
            .then((res) => {
              this.saveLoading = false;
              if (res.state == 0) {
                // if (res.value) {
                this.$emit("searchData");
                this.dialogVisible = false;
                // }
              } else {
                this.$alert(res.desc, this.$t("pop_up.Tips"), {
                  confirmButtonText: this.$t("pop_up.Determine"),
                });
              }
            })
            .catch((error) => {
              this.saveLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 取消
    resetForm() {
      this.dialogVisible = false;
      this.$refs.form.resetFields();
      Object.keys(this.form).forEach((v) => {
        if (v == "brandId") {
          this.form[v] = "1";
        } else {
          this.form[v] = "";
        }
      });
    },
    // 获取品牌下拉列表
    getList() {
      this.$axios
        .post(
          "/acb/2.0/bacb/common/combobox",
          {
            data: ["lightboxBrand"],
          },
          {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
            },
          }
        )
        .then((res) => {
          this.brandGroup = res.value?.lightboxBrand || [];
        });
    },
  },
  created() {
    this.getList();
  },
};
</script>
<style>
.formWidth {
}
</style>
