<template>
  <div>
    <el-dialog
      :title="!parkChannelId ? '添加通道' : '编辑通道'"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="500px"
      custom-class="dialog"
      @close="resetForm"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="120px" style="padding: 0">
        <el-form-item :label="$t('searchModule.Channel_name')" prop="parkChannelName">
          <el-input
            v-model="form.parkChannelName"
            class="formWidth"
            maxlength="50"
            placeholder="请输入通道名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="通道类型" prop="channelType" required>
          <el-select v-model="form.channelType" placeholder="请选择" class="formWidth">
            <el-option
              :label="item.desc"
              :value="item.code"
              v-for="item in channelTypeList"
              :key="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="form.channelType == 4"
          label="是否直连场外"
          prop="directConnect"
          required
        >
          <el-select v-model="form.directConnect" placeholder="请选择" class="formWidth">
            <el-option label="是" :value="1"></el-option>
            <el-option label="否" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属主控机" prop="lightBoxId" required>
          <el-select v-model="form.lightBoxId" placeholder="请选择" class="formWidth">
            <el-option
              :label="item.name"
              :value="item.lightBoxId"
              v-for="item in lightBoxList"
              :key="item.lightBoxId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="[3, 4].includes(form.channelType)"
          label="所属子车场"
          prop="parkId"
          required
        >
          <el-select v-model="form.parkId" placeholder="请选择" class="formWidth">
            <el-option
              :label="item.parkName"
              :value="item.parkId"
              v-for="item in parkList"
              :key="item.parkId"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="resetForm">返回</el-button>
        <el-button type="primary" @click="save" :loading="saveLoading">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    lightBoxList: {
      type: Array,
      default: () => [],
    },
    channelTypeList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialogVisible: false,
      parkChannelId: "",
      parkChannelCode: "",
      form: {
        parkChannelName: "",
        lightBoxId: "",
        channelType: "",
        parkId: "",
        directConnect: 0,
      },
      parkList: [],
      rules: {
        parkChannelName: [
          {
            required: true,
            message: "请输入通道名称",
            trigger: "blur",
          },
        ],
        channelType: [
          {
            required: true,
            message: "请选择通道类型",
            trigger: "blur",
          },
        ],
        lightBoxId: [
          {
            required: true,
            message: "请选择所属主控机",
            trigger: "change",
          },
        ],
        parkId: [
          {
            required: true,
            message: "请选择所属子车场",
            trigger: "blur",
          },
        ],
      },
      saveLoading: false,
    };
  },
  methods: {
    // 打开窗口
    open(data) {
      this.dialogVisible = true;
      this.parkChannelId = data?.parkChannelId;
      this.parkChannelCode = data?.parkChannelCode;
      this.getParkList();
      data &&
        Object.keys(this.form).forEach((v) => {
          this.form[v] = data[v] || 0;
        });
    },
    getParkList() {
      let url = "/acb/2.0/bacb/park/children/" + this.$route.query.parkId;
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          this.parkList = res.value?.filter(v => ![0, 1].includes(v.slaveRelation)) || [];
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    // 保存
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          this.form.parkId = [1, 2].includes(this.form.channelType) ? this.$route.query.parkId : this.form.parkId;
          this.$axios
            .post(
              "/acb/2.0/bacb/park/lightbox/channels/upsert",
              {
                data: {
                  parkChannelId: this.parkChannelId,
                  parkChannelCode: this.parkChannelCode,
                  ...this.form,
                },
              },
              {
                headers: {
                  "Content-Type": "application/json;charset=UTF-8",
                },
              }
            )
            .then((res) => {
              this.saveLoading = false;
              if (res.state == 0) {
                // if (res.value) {
                this.$emit("searchData");
                this.dialogVisible = false;
                // }
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            })
            .catch((error) => {
              this.saveLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 取消
    resetForm() {
      this.dialogVisible = false;
      this.$refs.form.resetFields();
      Object.keys(this.form).forEach((v) => {
        if (v == 'directConnect') {
          this.form[v] = 0;
        } else {
          this.form[v] = "";
        }
      });
    },
  },
};
</script>
<style>
.formWidth {
  width: 221.5px;
}


</style>
