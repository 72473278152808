<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.account_number')">
                <el-input
                  v-model.trim="formInline.account"
                  placeholder="账号"
                  size="12"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.full_name')">
                <el-input
                  v-model.trim="formInline.mcUserName"
                  placeholder="姓名"
                  size="12"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                v-if="$route.meta.authority.button.query"
                @click="
                  page = 1;
                  searchData();
                  loading = true;
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left">
              <el-button type="primary" icon="el-icon-plus" @click="addManager"
                >添加
              </el-button>
            </div>
            <div class="col_right mbd4"></div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="tableData"
        >
          <el-table-column
            type="index"
            :label="$t('list.index')"
            width="70"
            align="center"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            align="center"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
          <el-table-column label="操作" align="center" width="80">
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="a">编辑 </el-dropdown-item>
                  <el-dropdown-item
                    v-show="scope.row.mcUserState == 1"
                    command="b"
                    >离职</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-show="scope.row.mcUserState == 2"
                    command="b"
                    >在职</el-dropdown-item
                  >
                  <el-dropdown-item command="c">重置密码</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <add-manager
        ref="manager"
        :details="details"
        @searchData="searchData"
      ></add-manager>
    </div>
  </div>
</template>

  <script>
import { setIndex, jsMd5 } from "@/common/js/public.js";
import addManager from "./tollgathererManageAdd";
export default {
  name: "collectorM",
  data() {
    return {
      roleListName: [],
      temParkData: {},
      modelvalue: "",
      index: 0,
      total: 0,
      pageSize: 15,
      page: 1,
      loading: false,
      tableCols: [
        {
          prop: "operationName",
          label: this.$t("list.Merchant_Name"),
          width: "",
        },
        {
          prop: "deptName",
          label: this.$t("list.Department_to"),
          width: "",
        },
        {
          prop: "mcUserName",
          label: this.$t("list.full_name"),
          width: "100",
        },
        {
          prop: "mobile",
          label: this.$t("list.phone_number"),
          width: "130",
        },
        {
          prop: "account",
          label: this.$t("list.account_number"),
          width: "",
        },
        // {
        //   prop: "role",
        //   label: this.$t("list.role"),
        //   width: "",
        //   formatter: (row) => {
        //     if (row.role == 0) {
        //       return "岗亭收费员";
        //     } else if (row.role == 1) {
        //       return "岗亭运维员";
        //     }
        //   },
        // },
        {
          prop: "parkNames",
          label: this.$t("list.Manage_the_parking_lot"),
          width: "150",
        },
        {
          prop: "createdTime",
          label: this.$t("list.add_time"),
          width: "",
        },
        {
          prop: "mcUserState",
          label: this.$t("list.On_duty_status"),
          width: "100",
          formatter: (row) => {
            if (row.mcUserState == 1) {
              return "在职";
            } else if (row.mcUserState == 2) {
              return "离职";
            }
          },
        },
      ],
      tableData: [],
      //   roleList: [
      //     {
      //       code: 0,
      //       name: "岗亭收费员",
      //     },
      //     {
      //       code: 1,
      //       name: "岗亭运维员",
      //     },
      //   ],
      formInline: {
        account: "",
        mcUserName: "",
        // role: "",
        parkId: this.details.parkId,
        operationId: this.details.operationId,
      },
    };
  },
  watch: {
    modelvalue(newVal) {
      if (!newVal) {
        this.formInline.parkId = "";
      }
    },
  },
  methods: {
    addManager() {
      console.log(this.details, "this.details");
      this.$refs.manager.open();
    },
    /* 商户名称的下拉框 */
    getRoleList() {
      this.$axios
        .get("/acb/2.0/operation/nameList", {
          data: {},
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.roleListName = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // getRole() {
    //   // 角色:0岗亭收费员1岗亭运维员
    //   this.roleList = [
    //     {
    //       coed: "0",
    //       name: "岗亭收费员",
    //     },
    //     {
    //       coed: "1",
    //       name: "岗亭运维员",
    //     },
    //   ];
    //   // this.$axios.get("/acb/2.0/pdaManager/spinner").then((res) => {
    //   //   if (res.state == 0) {
    //   //     this.roleList = res.value;
    //   //   } else {
    //   //     this.$alert(res.desc, this.$t('pop_up.Tips'), {
    //   //       confirmButtonText: this.$t('pop_up.Determine'),
    //   //     });
    //   //   }
    //   // });
    // },

    querySearchAsync(queryString, cb) {
      this.$emit("valueChange", "");
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/bacb/systems/loginUser/getParkName";
      this.$axios
        .get(url, {
          data: {
            page: 0,
            pageSize: 20,
            parkName: queryString,
            slaveRelations: "0,1",
          },
        })
        .then((res) => {
          if (res.state == 0) {
            // this.parkData = res.value.list;
            this.temParkData = res.value.length
              ? res.value[0]
              : {
                  parkName: "",
                  parkId: "",
                };
            this.formInline.parkId = "";
            cb(res.value);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    handleSelect(item) {
      this.formInline.parkId = item.parkId;
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    handleCommand(cmd, data) {
      let obj = {
        ...data,
      };
      delete obj.parkNames;
      delete obj.parkIds;
      if (cmd == "a") {
        // this.$router.push({
        //   path: "/tollgathererManageAdd",
        //   query: obj,
        // });
        // 编辑时
        let isEdit = true;
        this.$refs.manager.open(data.mcUserId, isEdit);
      }
      if (cmd == "b") {
        this.$confirm("此操作将改变在职状态, 是否继续?", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.changeState(data.mcUserId, data.mcUserState);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      }
      if (cmd == "c") {
        // console.log(data, "重置密码");
        this.resetSeret(data);
      }
    },
    resetSeret({ mcUserId, account }) {
      this.$confirm("此操作将重置该用户密码, 是否继续?", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        type: "warning",
      })
        .then(() => {
          this.$axios
            .post("/acb/2.0/bacb/mcUser/resetPassword", {
              data: {
                mcUserId,
                // password: jsMd5(account, "Zhht@2019"),
              },
            })
            .then((res) => {
              if (res.state == 0) {
                // this.$confirm(res.desc, this.$t('pop_up.Tips'), {
                //   confirmButtonText: this.$t('pop_up.Determine'),
                //   cancelButtonText: "复制",
                //   type: "warning",
                // })
                //   .then(() => {
                //     this.searchData();
                //   })
                //   .catch(() => {
                //     this.copy("Zhht@2019");
                //   });
                this.$confirm(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                  type: "warning",
                }).then(() => {
                  this.searchData();
                });
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    copy(data) {
      let url = data;
      let oInput = document.createElement("input");
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$message({
        message: "复制成功",
        type: "success",
      });
      oInput.remove();
    },
    changeState(id, state) {
      state = state == 1 ? "2" : "1";
      let url = "/acb/2.0/bacb/mcUser/updateJobState";
      this.$axios
        .post(url, {
          data: {
            mcUserId: id,
            mcUserState: state,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            this.searchData();
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },

    // 搜索
    searchData() {
      let url = "/acb/2.0/bacb/mcUser/list";
      this.$axios
        .get(url, {
          data: {
            pageNum: this.page,
            pageSize: this.pageSize,
            ...this.formInline,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.tableData = res.value.list;
            this.total = res.value.total * 1;
            this.loading = false;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
  },
  props: {
    details: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  activated() {
    this.searchData();
  },
  components: { addManager },
  beforeDestroy() {
    sessionStorage.searchData = JSON.stringify(this.formInline);
    sessionStorage.searchFlag = 1;
  },
  created() {
    // this.getRole();//角色接口
    this.searchData();
    // this.getRoleList();
    console.log(this.details, "this.details-------");
  },

  mounted() {},
  computed: {},
};
</script>
    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <style scoped lang="stylus" rel="stylesheet/stylus">
    .content {
      overflow: hidden;

      .searchWrapper {
        overflow: hidden;
        // padding: 22px 22px 0;
        // margin-bottom: 20px;
      }

      .pagerWrapper {
        text-align: right;
        margin-top: 28px;
        font-size: 12px;

        .export {
          font-size: 12px;
          border: 1px solid #0099FA;
          border-radius: 4px;
          color: #0099FA;
          padding: 8px 13px;
          cursor: pointer;

          .iconfont {
            margin-right: 14px;
          }
        }
      }
    }

    .detail {
      width: 61.4%;
      height: 82.9%;
      position: absolute;
      top: 8.6%;
      left: 20.1%;
      background: #FFFFFF;
      box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
      border-radius: 3px;
      z-index: 1000;
      box-sizing: border-box;
      padding: 20px 0;

      .title {
        position: absolute;
        background: #3F4A56;
        border-radius: 3px 3px 0 0;
        color: #fff;
        width: 100%;
        top: 0;
        left: 0;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        padding: 0 12px;
        box-sizing: inherit;
        z-index: 100;

        .el-icon-close {
          float: right;
          margin-top: 14px;
        }
      }

      .info {
        margin-top: 20px;
        padding: 2.1%;
        height: 100%;
        overflow: auto;
        box-sizing: inherit;
      }
    }

    .mask {
      background: rgba(49, 53, 65, 0.6);
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 999;
    }

    .upload-demo {
      display: inline-block;
    }
</style>
