<template>
  <div>
    <el-dialog
      title="查看设备"
      :visible.sync="dialogVisible"
      width="500"
      custom-class="dialog-detail"
    >
      <el-form ref="form" label-width="90px">
        <el-form-item label="设备编号：">
          <span class="of" :title="equipmentCode"> {{ equipmentCode }}</span>
        </el-form-item>
        <el-form-item label="设备名称：">
          <span class="of" :title="equipmentName"> {{ equipmentName }}</span>
        </el-form-item>
        <el-form-item label="关联泊位：">
          <div class="listStyle">
            {{ hasSelectedBerth.join() }}
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="dialogVisible = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      equipmentCode: "",
      equipmentName: "",
      hasSelectedBerth: [],
    };
  },
  methods: {
    open() {
      this.dialogVisible = true;
    },
    getDetail({ equipmentId }) {
      let url = "/acb/2.0/bacb/equipment/equipmentDetail/" + equipmentId;
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          this.equipmentCode = res.value.equipmentCode;
          this.equipmentName = res.value.equipmentName;
          this.hasSelectedBerth = res.value.outBerthList?.map((v) => v.berthCode) || [];
        }
      });
    },
  },
  mounted() {},
};
</script>
<style scoped lang="stylus">
.dialog-detail {
  .el-form-item {
    margin-bottom: -14px;
  }
}
.listStyle {
  width: 500px;
  height: 200px;
  overflow: auto;
}
.of {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  width: 250px;
}
</style>
