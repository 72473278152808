<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          ref="form"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Berth_number')" prop="berthCode">
                <el-input v-model="formInline.berthCode" placeholder="请输入内容"></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Equipment_number')" prop="equipmentCode">
                <el-input v-model="formInline.equipmentCode" placeholder="请输入内容"></el-input>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  page = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
              <el-button type="info" icon="el-icon-delete" @click="resetForm()">{{ $t('button.reset') }}</el-button>
            </div>
          </div>
          <div class="col_box_boder"></div>
          <!-- 如果没有下面按钮就不需要 -->
          <div class="col_box h44">
            <div class="col_left">
              <el-button
                type="primary"
                icon="el-icon-plus"
                v-if="!authority.button.add"
                @click="addBerth"
                >{{ $t('button.addto') }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF">
        <el-table v-loading="loading" :data="tableData" style="width: 100%" highlight-current-row>
          <el-table-column type="index" :label="$t('list.index')" width="70"></el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column label="操作" width="80">
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="!authority.button.view" command="a"
                    >查看</el-dropdown-item
                  >
                  <el-dropdown-item v-if="!authority.button.edit" command="b"
                    >编辑</el-dropdown-item
                  >
                  <el-dropdown-item v-if="!authority.button.delete" command="c">{{
                    scope.row.berthState == 1 ? "下线" : "上线"
                  }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <add-berth ref="addBerth" @searchData="searchData"></add-berth>
        <berth-detail ref="berthDetail"></berth-detail>
      </div>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import addBerth from "./add";
import berthDetail from "./Detail";
import { exportExcelNew } from "@/common/js/public";
export default {
  name: "hello",
  props: {
    authority: {
      type: Object,
      default: () => ({ button: {} }),
    },
  },
  data() {
    return {
      total: 0,
      pageSize: 15,
      page: 1,
      loading: false,
      tableData: [],
      formInline: {
        parkId: this.$route.query.parkId,
        berthCode: "",
        equipmentCode: "",
      },
      tableCols: [
        {
          prop: "berthCode",
          label: this.$t("list.Berth_number"),
        },
        {
          prop: "berthTag",
          label: this.$t("list.Service_label"),
          width: "200",
          formatter: (row, column) => {
            let arr = [];
            if (row.charge) {
              arr.push("充电泊位");
            }
            if (row.berthDisability) {
              arr.push("残疾人泊位");
            }
            return arr.join("、");
          },
        },
        {
          prop: "berthState",
          label: "泊位状态",
          formatter: (row, column) => {
            if (row.berthState == 1) {
              return (
                <span>
                  <i class="on-line"></i>上线
                </span>
              );
            } else {
              return (
                <span>
                  <i class="on-line off-line"></i>下线
                </span>
              );
            }
          },
        },
        {
          prop: "equipmentCode",
          label: this.$t("list.Equipment_number"),
        },
        {
          prop: "updatedTime",
          label: this.$t("list.update_time"),
        },
      ],
    };
  },
  methods: {
    resetForm() {
      this.$refs.form.resetFields();
    },
    addBerth() {
      this.$refs.addBerth.open();
    },
    handleCommand(cmd, data) {
      if (cmd == "a") {
        this.$refs.berthDetail.getDetail(data);
        this.$refs.berthDetail.open();
      }
      if (cmd == "b") {
        this.$refs.addBerth.open(data);
      }
      if (cmd == "c") {
        data.berthState == 1 ? this.offLineBerth(data) : this.onLineBerth(data);
      }
    },
    offLineBerth(data) {
      this.$confirm("泊位下线后，泊位服务不再开放给车主，请确认?", this.$t('pop_up.Tips'), {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .post("/acb/2.0/bacb/berth/offLine", {
              data: {
                berthIds: data.berthId,
              },
            })
            .then((res) => {
              if (res.state == 0) {
                this.$message({
                  type: "success",
                  message: "下线成功！",
                });
                this.page = 1;
                this.searchData();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    onLineBerth(data) {
      this.$axios
        .post("/acb/2.0/bacb/berth/onLine", {
          data: {
            berthIds: data.berthId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.$message({
              type: "success",
              message: "上线成功！",
            });
            this.page = 1;
            this.searchData();
          }
        });
    },
    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    // 搜索
    searchData() {
      this.loading = true;
      this.$axios
        .get("/acb/2.0/bacb/berth/list", {
          data: {
            pageNum: this.page,
            pageSize: this.pageSize,
            ...this.formInline,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            if (res.value) {
              this.tableData = res.value.list;
              this.total = res.value.total * 1;
            } else {
              this.tableData = [];
              this.total = 0;
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    init() {
      this.$refs.form && this.$refs.form.resetFields();
      this.searchData();
    },
  },
  components: {
    addBerth,
    berthDetail,
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
  height 35px
.content
  overflow hidden
  .on-line {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #09D335;
    margin-right: 8px;
    position: relative;
    top: -2px;
    &.off-line {
      background: #FF0000;
    }
  }
  .pagerWrapper
    text-align right
    margin-top 28px
    font-size 12px
    .export
      font-size 12px
      .iconfont
        margin-right 0
</style>
<style scoped>
.checked {
  display: block !important;
}
.isShow {
  display: none !important;
}
</style>
