var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看泊位",
            visible: _vm.dialogVisible,
            width: "500px",
            "custom-class": "dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "berthform",
              attrs: { model: _vm.formInline, "label-width": "90px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "泊位编号：", prop: "berthCode" } },
                [_c("span", [_vm._v(_vm._s(_vm.formInline.berthCode))])]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "服务标签：" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.serviceAttr,
                        callback: function ($$v) {
                          _vm.serviceAttr = $$v
                        },
                        expression: "serviceAttr",
                      },
                    },
                    [
                      _c(
                        "el-checkbox",
                        { attrs: { label: "3", disabled: "" } },
                        [_vm._v("充电泊位")]
                      ),
                      _c(
                        "el-checkbox",
                        { attrs: { label: "4", disabled: "" } },
                        [_vm._v("残疾人泊位")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("返回")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }